<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        <img
          src="https://storage.googleapis.com/wizville-assets-eu/static/images/wizville/public/local_monitor_logo.svg"
          alt="Logo WizVille Local Monitor"
          style="width: 140px"
        />
      </router-link>
      <a
        role="button"
        :class="['navbar-burger burger', { 'is-active': isActive }]"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarLanguages"
        @click="toggleBurger"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      id="navbarLanguages"
      :class="['navbar-menu', { 'is-active': isActive }]"
    >
      <div class="navbar-end">
        <div class="navbar-item">
          <a
            v-for="locale in availableLanguages"
            :key="locale"
            :class="[{ active: locale === $i18n.locale }]"
            @click="setLocale(locale)"
          >
            {{ locale.toUpperCase() }}
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "WizMonitorHeader",
  data() {
    return {
      isActive: false,
      availableLanguages: ["fr", "en"]
    }
  },
  methods: {
    toggleBurger() {
      this.isActive = !this.isActive
    },
    setLocale(locale) {
      this.$router.push({ params: { lang: locale }, query: this.$route.query })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@localMonitor/assets/stylus/medias.styl'

.navbar
  padding: 8px 10px

.navbar-item img
  max-height: none
  padding-top: 10px

div.navbar-item
  a
    color: #838383
    margin 3px

    &.active
      font-weight: bold

  span
    margin: 3px
</style>
